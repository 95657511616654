import { Location } from "@reach/router"
import { ActionButton, FooterSimple, FormContactUsV3, Link, Provider, ThemeContext,CXToast } from "Components"
import Menu from "Components/Menu"
import Config, { nav, singlePageNav } from "Data"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import { IntlProvider } from "react-intl"
import { loadReCaptcha } from "react-recaptcha-google"
import ScrollToTop from "react-scroll-up"
import { Sticky, StickyContainer } from "react-sticky"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import styled from "styled-components"
import { GlobalStyle, media, mixins, Nav, ScrollToTopButton } from "Styles"
import { theme } from "Theme"
import logo from "../../static/logo/ciso_white.png"

const { colors, fontSize } = theme

const LayoutStyled = styled.div`
  width: 100%;
  ${({ mode }) => mode === "dark" && `color: #FFF;background: #212121;border-color: #eeeeee;`};
  ${({ mode }) => mode === "transparent" && `color: #FFF;background: #ffffff00;border-color: #eeeeee;`};
`

const LayoutStyled1 = styled.div`
  width: 100%;
  ${({ mode }) => mode === "dark" && `color: #FFF;background: #212121;border-color: #eeeeee;`};
`
const HeaderContainer = styled.header`
  ${mixins.flexBetween};
  padding: 0;
  position: ${(props) => (props.sticky ? "relative" : "absolute")};
  top: 0;
  ${({ transparentHeader }) => !transparentHeader && `box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);`}
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  &.header-solid {
    background: ${theme.colors.white.base};
    & > nav {
      button,
      a:not(.action-cta),
      .nav-logo {
        margin: 0;
        color: ${theme.colors.black.base};
      }
    }
    ${({ mode }) =>
    mode === "dark" &&
    `color: #FFF;background: #212121;border-color: #eeeeee;& > nav {
        button,
        a:not(.action-cta),
        .nav-logo {
          color: ${theme.colors.white.base};
        }
      }`};
  }

  &.header-transparent {
    background: "#ffffff00";
    & > nav {
      button,
      a:not(.action-cta),
      .nav-logo {
        margin: 0;
        color: ${theme.colors.white.base};
      }
    }
    ${({ mode }) =>
    mode === "transparent" &&
    `color: #FFF;background: #212121;border-color: #eeeeee;& > nav {
        button,
        a:not(.action-cta),
        .nav-logo {
          color: ${theme.colors.white.base};
        }
      }`};
  }
`

const StyledLink = styled((props) => <Link {...props} />)`
  display: flex;
  font-weight: 700;
  align-items: center;
  &:hover {
    color: inherit;
  }
`

const Navbar = styled(Nav)`
  ${mixins.flexBetween};
  color: ${colors.black.base};
  position: relative;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  z-index: 12;
  /* min-height: 145px; */
  min-height: 120px;
  padding: 0 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 0 2rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`

const Hamburger = styled.div`
  ${mixins.flexCenter};
  overflow: visible;
  margin: 0;
  padding: 8px 0 8px 16px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  border-radius: ${theme.borderRadius.ui};
  background-color: transparent;
  display: none;
  ${media.desktop`display: flex;`};
`
const HamburgerBox = styled.div`
  position: relative;
  display: inline-block;
  width: ${theme.hamburgerWidth}px;
  height: ${theme.hamburgerWidth}px;
`
const HamburgerInner = styled.div`
  background-color: #212121;
  ${({ mode }) => mode === "dark" && `background-color: #FFF;border-color: #eeeeee;`};

  position: absolute;
  width: ${theme.hamburgerWidth}px;
  height: 3px;
  border-radius: ${theme.borderRadius.round};
  top: 50%;
  left: 0;
  right: 0;
  transition-duration: 0.22s;
  transition-property: transform;
  transition-delay: ${(props) => (props.menuOpen ? `0.12s` : `0s`)};
  transform: rotate(${(props) => (props.menuOpen ? `225deg` : `0deg`)});
  transition-timing-function: cubic-bezier(
    ${(props) => (props.menuOpen ? `0.215, 0.61, 0.355, 1` : `0.55, 0.055, 0.675, 0.19`)}
  );
  &:before,
  &:after {
    content: "";
    background-color: inherit;
    display: block;
    background-color: inherit;
    position: absolute;
    left: auto;
    right: 0;
    width: ${theme.hamburgerWidth}px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: ${theme.borderRadius.round};
  }
  &:before {
    width: ${(props) => (props.menuOpen ? `100%` : `80%`)};
    top: ${(props) => (props.menuOpen ? `0` : `-10px`)};
    opacity: ${(props) => (props.menuOpen ? 0 : 1)};
    transition: ${(props) => (props.menuOpen ? theme.hamBeforeActive : theme.hamBefore)};
  }
  &:after {
    width: ${(props) => (props.menuOpen ? `100%` : `80%`)};
    bottom: ${(props) => (props.menuOpen ? `0` : `-10px`)};
    transform: rotate(${(props) => (props.menuOpen ? `-90deg` : `0`)});
    transition: ${(props) => (props.menuOpen ? theme.hamAfterActive : theme.hamAfter)};
  }
`

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  ${media.desktop`display: none;`};
`

const NavList = styled.div`
  margin: 0;
  display: flex;
  div {
    ${mixins.flexBetween};
  }
  @media (min-width: ${theme.breakpoints.m}) {
    .action-cta {
      margin-left: 1rem;
    }
  }
`
const NavListItem = styled.span`
  cursor: pointer;
  margin: 0 10px;
  position: relative;
  text-transform: capitalize;
  font-size: ${fontSize.small};
`

const NavLink = styled((props) => <Link {...props} />)`
  ${mixins.link};
  padding: 6px 10px;
  font-weight: bold;
  &.activeItem {
    box-shadow: inset 0px -2px ${colors.primary.base};
  }
`

const NavExternal = styled.a`
  padding: 12px 10px;
  font-weight: bold;
`

const TextLogo = styled.span`
  font-size: ${fontSize.xxlarge};
`

const ScrollToTopStyled = styled.span`
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    display: none;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`

class SinglePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentModal: null,
      shareEvent: null,
      showEvents: false,
      lastScrollTop: 0,
      scrollDirection: "none",
      menuOpen: false,
      isHide: false,
      isMounted: false,
    }
  }

  componentDidMount() {
    loadReCaptcha()
    if (this.captchaObject) {
      this.captchaObject.reset()
      this.captchaObject.execute()
    }

    this.setState({
      currentModal: null,
      shareEvent: null,
      showEvents: false,
    })
    // fetch("/locale.json")
    //   .then((res) => res.json())
    //   // .then(data => console.log("GEOIP: ", data))
    //   // .then(data => this.setLocale(data))
    //   .catch((err) => console.error("Error fetching data from /locale.json endpoint"))
    setTimeout(() => this.setState({ isMounted: true }), 100)
  }

  onLoadRecaptcha() {
    // console.log("onLoadRecaptcha!")
    if (this.captchaObject) {
      this.captchaObject.reset()
      // console.log("onLoadRecaptcha After Reset!")
      this.captchaObject.execute()
    }
  }

  toggleModal = (key, param) => (e) => {
    e.preventDefault()
    if (this.state.currentModal) {
      this.handleModalCloseRequest()
      return
    }

    this.setState({
      ...this.state,
      currentModal: key,
      shareEvent: param.shareEvent,
      showEvents: param.showEvents,
    })
  }

  handleModalCloseRequest = () => {
    this.setState({
      ...this.state,
      currentModal: null,
    })
  }

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen })

  handleMenuClick = (e) => {
    const target = e.target
    const isLink = target.hasAttribute("href")
    const isContainer = target.classList && target.classList[0].includes("MenuContainer")
    if (isLink || isContainer) {
      this.toggleMenu()
    }
  }

  render() {
    const { props } = this
    const { locale, region, children, simple, sticky, transparentHeader, transparentFooter, path, type } = props
    const { scrollDirection, menuOpen, isMounted, currentModal } = this.state
    // console.log("locale in layout: ", locale)
    // console.log("region in layout: ", region)
    return (
      <StaticQuery
        query={graphql`
          query SinglePageQuery {
            banners: bannersJson {
              banner {
                attend {
                  childImageSharp {
                    gatsbyImageData(width: 1440, placeholder: BLURRED)
                  }
                }
                sponsor {
                  childImageSharp {
                    gatsbyImageData(width: 1440, placeholder: BLURRED)
                  }
                }
                sharing {
                  childImageSharp {
                    gatsbyImageData(width: 1440, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        `}
        render={() => {
          const page = "Menu"
          const localizedRegion = region === "us" ? "/" : `/${region}/`

          return (
            <>
              <CXToast/>
              <FormContactUsV3
                title={`Have a question for the ${Config.brand} team?`}
                subtitle="Call us or submit below:"
                isOpen={currentModal === "modal_contactus1"}
                onAfterOpen={this.handleOnAfterOpenModal}
                onRequestClose={this.handleModalCloseRequest}
                closeModal={this.toggleModal("modal_contactus")}
              />
              <Provider>
                <IntlProvider locale={locale}>
                  <ThemeContext.Consumer>
                    {({ mode }) => (
                      <>
                        <Location>
                          {({ location }) => (
                            <Fragment>
                              <GlobalStyle />
                              <LayoutStyled1 mode={mode}>
                                <StickyContainer>
                                  <Sticky disableCompensation>
                                    {({
                                      style,

                                      distanceFromTop,
                                    }) => (
                                      <HeaderContainer
                                        mode={mode}
                                        style={sticky ? style : null}
                                        className={`${"header-transparent"}`}
                                        ref={(el) => (this.header = el)}
                                        scrollDirection={scrollDirection}
                                        transparentHeader={transparentHeader}>
                                        {distanceFromTop * -1 > 140 && transparentHeader && (
                                          <Helmet bodyAttributes={{ class: "sticky-inverted" }} />
                                        )}
                                        {distanceFromTop * -1 > 140 && !transparentHeader && (
                                          <Helmet bodyAttributes={{ class: "sticky-default" }} />
                                        )}
                                        <Navbar>
                                          <StyledLink
                                            to={`${localizedRegion}`}
                                            alt={`${Config.brand} Logo`}
                                            track={page}
                                            title={`${Config.brand} Logo`}>
                                            <TextLogo className="nav-logo">
                                              <img
                                                src={logo}
                                                alt=""
                                                style={{ margin: "0", padding: "4px 0", width: "auto", height: "75px" }}
                                              />
                                            </TextLogo>
                                          </StyledLink>

                                          {singlePageNav && (
                                            <>
                                              {singlePageNav.length > 0 && isMounted && !simple && (
                                                <Hamburger onClick={this.toggleMenu} className={"hamburger-button"}>
                                                  <HamburgerBox>
                                                    <HamburgerInner menuOpen={menuOpen} />
                                                  </HamburgerBox>
                                                </Hamburger>
                                              )}
                                              <NavLinks className={"nav-links"}>
                                                <NavList>
                                                  <TransitionGroup>
                                                    {singlePageNav &&
                                                      !simple &&
                                                      singlePageNav.map(({ url, name, offset, section_ref }, i) => (
                                                        <CSSTransition classNames="fadedown" timeout={3000} key={i}>
                                                          <NavListItem
                                                            style={{
                                                              transitionDelay: `${i * 300}ms`,
                                                            }}>
                                                            {url.toString().includes("http") ? (
                                                              <NavExternal
                                                                href={url}
                                                                target="_blank"
                                                                track={page}
                                                                title={name}>
                                                                {name}
                                                              </NavExternal>
                                                            ) : (
                                                              <NavLink
                                                                to={
                                                                  location.pathname === url
                                                                    ? ""
                                                                    : `${localizedRegion === "/"
                                                                      ? url
                                                                      : localizedRegion + url
                                                                    }`
                                                                }
                                                                track={page}
                                                                title={name}
                                                                offset={500}
                                                                section_ref={section_ref}
                                                                activeClassName={"activeItem"}>
                                                                {name}
                                                              </NavLink>
                                                            )}
                                                          </NavListItem>
                                                        </CSSTransition>
                                                      ))}

                                                    {/* <CSSTransition classNames="fadedown" timeout={3000}>
                                                      <div
                                                        style={{
                                                          transitionDelay: `600ms`,
                                                        }}>
                                                        <ActionButton
                                                          className={`action-cta`}
                                                          onClick={this.toggleModal("modal_contactus1", {
                                                            shareEvent: null,
                                                            showEvents: false,
                                                          })}
                                                          title={`Contact Us`}
                                                          track={page}>
                                                          Contact Us
                                                        </ActionButton>
                                                      </div>
                                                    </CSSTransition> */}
                                                  </TransitionGroup>
                                                </NavList>
                                              </NavLinks>
                                            </>
                                          )}
                                        </Navbar>

                                        {singlePageNav && !simple && (
                                          <Menu
                                            type={type}
                                            navLinks={singlePageNav}
                                            menuOpen={menuOpen}
                                            path={path}
                                            handleMenuClick={(e) => this.handleMenuClick(e)}
                                            button_text={`Contact Us`}
                                            button_action={this.toggleModal("modal_contactus", {
                                              shareEvent: null,
                                              showEvents: false,
                                            })}
                                          />
                                        )}
                                      </HeaderContainer>
                                    )}
                                  </Sticky>
                                  {children}
                                </StickyContainer>

                                <ScrollToTopStyled>
                                  <ScrollToTop
                                    showUnder={160}
                                    style={{ zIndex: 9, bottom: 120, right: 48, transitionDuration: "0.4s" }}>
                                    <ScrollToTopButton>
                                      <span>UP</span>
                                    </ScrollToTopButton>
                                  </ScrollToTop>
                                </ScrollToTopStyled>

                                {/* <CookieBannerComponent /> */}

                                {/* <FooterLocale region={region} /> */}
                                <FooterSimple transparentFooter={transparentFooter} />
                              </LayoutStyled1>
                            </Fragment>
                          )}
                        </Location>
                      </>
                    )}
                  </ThemeContext.Consumer>
                </IntlProvider>
              </Provider>
            </>
          )
        }}
      />
    )
  }
}

export default SinglePage

SinglePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
